import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="removals"
export default class extends Controller {
  connect() {
    console.log(this.element);
    this.element.addEventListener(
      "animationend",
      this.animationEnded.bind(this)
    );
  }

  animationEnded(event) {
    this.element.remove();
  }
}
